import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { CPROF_FETCH_ALL_ACTION } from "../../../redux/actions/checkout-profile";
import { GET_ADDRESS_DETAIL_ACTION } from "../../../redux/actions/account";

import { checkoutProfileSelector } from "../../../redux/selectors/checkout-profile";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import * as cprof from "../../../redux/actions/checkout-profile";
import storeUtil from "../../../utils/storeUtil";
import { debounce, get } from "lodash-es";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../../_foundation/constants/common";
import * as ROUTES from "../../../constants/routes";
import { useSite } from "../../../_foundation/hooks/useSite";

import { SpecialTitle } from "../../../hdm/components/hdm-special-title/SpecialTitle";
import {
  StyledBox,
  StyledButton,
  StyledCardEmpty,
  StyledGrid,
  StyledLink,
  StyledTextField,
  StyledTypography,
} from "../../../hdm/elements";
import TitleCard from "../../custom-components/styled-title-card/TitleCard";
import { AccountLinksGridViewLayout } from "../../widgets/account-links-gridview";
import DireccionDeEntrega from "./DireccionDeEntrega";
import MetodosDePago from "./MetodosDePago";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { HDmHeadingTitle } from "../../../hdm/components/hdm-heading-main-title/HdmHeadingMainTitle";
import { allowableShipModesSelector } from "../../../redux/selectors/order";
import { SHIPMODE } from "../../../constants/order";
import AccountSidebar from "../../../hdm/widgets/account-sidebar/AccountSidebar";

interface IRefsHeightProps {
  addressListHeight: number | undefined;
  paymethodsHeight: number | undefined;
}

interface Props {
  updateDataProfile: any;
}
export type cardInfo = {
  pay_payMethodId: string | null;
  pay_cc_brand: string | null;
  pay_payment_method: string | null;
  pay_account_hidden: string | null;
  pay_expire_month: string | null;
  pay_expire_year: string | null;
};

const ProfileCreate = (props: Props) => {
  const { updateDataProfile } = props;

  const { t } = useTranslation();
  const storeConfData = useSelector(onlineStoreRdc);
  const shipModes = useSelector(allowableShipModesSelector);
  const [selectedDireccion, setSelectedDireccion] = useState<any>(null);
  const [selectedMetodoPago, setSelectedMetodoPago] = useState<any>(null);
  const [linkedAccount, setLinkedAccount] = useState(false);
  const [savedCard, setSaveCard] = useState<cardInfo>({
    pay_payMethodId: null,
    pay_cc_brand: null,
    pay_payment_method: null,
    pay_account_hidden: null,
    pay_expire_month: null,
    pay_expire_year: null,
  });
  const [isValidAlias, setIsValidalias] = useState<boolean>(false);
  const [alias, setAlias] = useState<string>("");
  const fromState = useSelector(checkoutProfileSelector);
  const addressDetails = useSelector(addressDetailsSelector);
  const [misDirecciones, setMisDirecciones] = useState<any>(null);
  const [checkoutProfileList, setCheckoutProfileList] = useState<any[]>([]);
  const [listCardsLength, setListCardsLength] = useState<number>(0);
  const navigate = useNavigate();

  const [errorAlias, setErrorAlias] = useState<any>({ error: false, errorMsg: "" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const cancels: Canceler[] = [];
  const CancelToken = Axios.CancelToken;
  const widget = getDisplayName(ProfileCreate);
  const { mySite } = useSite();
  const payloadBase: any = {
    widget,
    cancelToken: new CancelToken((c) => cancels.push(c)),
  };
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  const [refHeights, setRefHeights] = useState<IRefsHeightProps>();

  useEffect(() => {
    if (updateDataProfile) {
      setAlias(updateDataProfile.profile.xchkout_ProfileName);
    } else {
      setAlias("");
    }
  }, [updateDataProfile]);

  useEffect(() => {
    if (mySite) {
      const payload = {
        ...payloadBase,
      };
      dispatch(GET_ADDRESS_DETAIL_ACTION(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  useEffect(() => {
    dispatch(CPROF_FETCH_ALL_ACTION({ ...payloadBase }));
  }, []);

  useEffect(() => {
    if (addressDetails && addressDetails?.contactList) {
      let hasDirecciones = false;
      let hasDireccionPrincipal = false;

      if (addressDetails.contactList.length !== 0) {
        hasDirecciones = true;
      }
      if (addressDetails?.addressLine && addressDetails?.city && addressDetails?.state && addressDetails?.zipCode) {
        hasDireccionPrincipal = true;
      }

      if (hasDirecciones) {
        const allDirecciones = JSON.parse(JSON.stringify(addressDetails.contactList));

        if (hasDireccionPrincipal) {
          const principalAddress = {
            addressId: addressDetails.addressId,
            addressLine: addressDetails.addressLine,
            addressType: addressDetails.addressType,
            attributes: addressDetails.attributes,
            city: addressDetails.city,
            country: addressDetails.country,
            email1: addressDetails.email1,
            firstName: addressDetails.firstName,
            lastName: addressDetails.lastName,
            nickName: addressDetails.nickName,
            primary: addressDetails.primary,
            state: addressDetails.state,
            zipCode: addressDetails.zipCode,
            zipCodeInBlocklist: addressDetails.zipCodeInBlocklist,
          };

          allDirecciones.unshift(principalAddress);
          setMisDirecciones(allDirecciones);
        } else {
          setMisDirecciones(allDirecciones);
        }
      } else if (hasDireccionPrincipal) {
        const principalAddress = {
          addressId: addressDetails.addressId,
          addressLine: addressDetails.addressLine,
          addressType: addressDetails.addressType,
          attributes: addressDetails.attributes,
          city: addressDetails.city,
          country: addressDetails.country,
          email1: addressDetails.email1,
          firstName: addressDetails.firstName,
          lastName: addressDetails.lastName,
          nickName: addressDetails.nickName,
          primary: addressDetails.primary,
          state: addressDetails.state,
          zipCode: addressDetails.zipCode,
          zipCodeInBlocklist: addressDetails.zipCodeInBlocklist,
        };

        setMisDirecciones([principalAddress]);
      } else {
        setMisDirecciones([]);
      }
    } else if (addressDetails) {
      if (addressDetails?.addressLine && addressDetails?.city && addressDetails?.state && addressDetails?.zipCode) {
        const principalAddress = {
          addressId: addressDetails.addressId,
          addressLine: addressDetails.addressLine,
          addressType: addressDetails.addressType,
          attributes: addressDetails.attributes,
          city: addressDetails.city,
          country: addressDetails.country,
          email1: addressDetails.email1,
          firstName: addressDetails.firstName,
          lastName: addressDetails.lastName,
          nickName: addressDetails.nickName,
          primary: addressDetails.primary,
          state: addressDetails.state,
          zipCode: addressDetails.zipCode,
        };

        setMisDirecciones([principalAddress]);
      } else {
        setMisDirecciones([]);
      }
    }
  }, [addressDetails]);

  useEffect(() => {
    if (alias.length !== 0 && alias.substring(0, 1) !== " ") {
      setIsValidalias(true);
    } else {
      setIsValidalias(false);
    }
  }, [alias]);

  useEffect(
    () => {
      const rc = getCheckoutProfileDetails(fromState);
      setCheckoutProfileList(rc);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromState]
  );

  const handleGuardadoExito = () => {
    const payload = {
      ...payloadBase,
    };
    dispatch(GET_ADDRESS_DETAIL_ACTION(payload));
  };

  const getCheckoutProfileDetails = useMemo(
    () => (response) => {
      const p = response.curUserProfiles
        .filter((p) => p.xchkout_ProfileId && p.xchkout_ProfileName)
        .map((p) => {
          const rc = {
            ...p,
            billingData: storeUtil.toMap(get(p, "protocolData", []), "name"),
          };
          return rc;
        });
      return p;
    },
    []
  );

  const handleSelectedDireccion = (direccion) => {
    setSelectedDireccion(direccion);
  };

  const handleSelectedMetodoPago = (metodo) => {
    if((metodo?.policyName === "VISA" && listCardsLength < 1 ) || metodo === "none" || (metodo?.paymentMethodName === "PayPal" && !linkedAccount)) {
      setSelectedMetodoPago(null);
      return;
    }
    setSelectedMetodoPago(metodo);
  };

  const handleChanegeAlias = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let valor = event.target.value;

    const patt = new RegExp(/^[a-zA-ZñÑ0-9][\sa-zA-ZñÑ0-9]*$/g); ///^S[A-Za-z0-9ñÑáéíóúÝÉÝÓÚ´\s]+$/g
    const isValid = patt.test(valor);
    const firstCharSpace = new RegExp(/^\s/).test(valor);

    if (firstCharSpace) {
      valor = "";
    }

    if (isValid || valor.trim() === "") {
      setAlias(valor);
      setErrorAlias({
        error: false,
        errorMsg: "",
      });
    } else {
      setErrorAlias({
        error: true,
        errorMsg: t("CheckoutProfile.ErrorMessageInvalidAlias"),
      });
    }
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valor = event.target.value;
    if (valor === "") {
      setErrorAlias({
        error: true,
        errorMsg: t("CheckoutProfile.ErrorMessageInvalidAlias"),
      });
    } else {
      setErrorAlias({
        error: false,
        errorMsg: "",
      });
    }
  };

  //
  //document.getElementById("alias").addEventListener('keydown', function (e) {
  //  if (this.value.length === 0 && e.which === 32) e.preventDefault();
  //}

  const handleCrearProfile = () => {
    const existe = checkoutProfileList.filter(
      (profile) => profile.xchkout_ProfileName.trim().toLowerCase() === alias.trim().toLowerCase()
    );
    // console.log("existe => ", existe);

    if (existe.length !== 0) {
      setAlias(alias.trim());
      setErrorAlias({
        error: true,
        errorMsg: t("CheckoutProfile.profileAlreadyExist"),
      });
      return;
    }

    const currentUser = localStorageUtil.get(CURRENT_USER);
    const isCard = savedCard.pay_cc_brand !== null;
    let body: any = null;
    if (
      selectedMetodoPago.paymentMethodName === "Oxxo" ||
      selectedMetodoPago.paymentMethodName === "PayInStore" ||
      selectedMetodoPago.paymentMethodName === "OpenPay" ||
      selectedMetodoPago.paymentMethodName === "PayPal" ||
      selectedMetodoPago.paymentMethodName === "CreditAR" ||
      selectedMetodoPago.cardType
    ) {
      body = {
        URL: "noURL",
        billing_addressId: selectedDireccion.addressId,
        billing_addressLine: selectedDireccion.addressLine,
        billing_attribute: selectedDireccion.attribute,
        billing_city: selectedDireccion.city,
        billing_country: "MX",
        billing_firstName: selectedDireccion.firstName,
        billing_lastName: selectedDireccion.lastName,
        billing_nickName: selectedDireccion.nickName,
        billing_state: selectedDireccion.state,
        billing_zipCode: selectedDireccion.zipCode,
        pay_payment_method: selectedMetodoPago.paymentMethodName || selectedMetodoPago.cardType,
        profileName: alias.trim(),
        shipping_addressId: selectedDireccion.addressId,
        shipping_addressLine: selectedDireccion.addressLine,
        shipping_attribute: selectedDireccion.attribute,
        shipping_city: selectedDireccion.city,
        shipping_country: "MX",
        shipping_firstName: selectedDireccion.firstName,
        shipping_lastName: selectedDireccion.lastName,
        shipping_modeId: shipModes?.find((shipMode) => shipMode?.shipModeCode === SHIPMODE.shipModeCode.PickUp)
          ?.shipModeId,
        shipping_nickName: selectedDireccion.nickName,
        shipping_state: selectedDireccion.state,
        shipping_zipCode: selectedDireccion.zipCode,
        userId: currentUser.userId,
      };
      if (isCard) {
        body = { ...body, ...savedCard };
      }
    }
    // return

    // console.log('body', body)

    if (body) {
      dispatch(
        cprof.CPROF_CREATE_ACTION({
          ...payloadBase,
          widget: "CheckoutProfileCreate",
          body,
        })
      );

      navigate(ROUTES.CHECKOUT_PROFILES);
    }
  };

  const handleEditarPerfil = () => {
    const currentUser = localStorageUtil.get(CURRENT_USER);

    const existe = checkoutProfileList.filter(
      (profile) => profile.xchkout_ProfileName.trim().toLowerCase() === alias.trim().toLowerCase()
    );

    if (mySite.isB2B && existe.length !== 0) {
      setAlias(alias.trim());
      setErrorAlias({
        error: true,
        errorMsg: t("CheckoutProfile.profileAlreadyExist"),
      });
      return;
    }

    const isCard = savedCard.pay_cc_brand !== null;

    let body: any = null;
    if (
      selectedMetodoPago.paymentMethodName === "Oxxo" ||
      selectedMetodoPago.paymentMethodName === "PayInStore" ||
      selectedMetodoPago.paymentMethodName === "OpenPay" ||
      selectedMetodoPago.paymentMethodName === "PayPal" ||
      selectedMetodoPago.cardType ||
      selectedMetodoPago.paymentMethodName 
    ) {
      body = {
        URL: "noURL",
        billing_addressId: selectedDireccion.addressId,
        billing_addressLine: selectedDireccion.addressLine,
        billing_attribute: selectedDireccion.attribute,
        billing_city: selectedDireccion.city,
        billing_country: "MX",
        billing_firstName: selectedDireccion.firstName,
        billing_lastName: selectedDireccion.lastName,
        billing_nickName: selectedDireccion.nickName,
        billing_state: selectedDireccion.state,
        billing_zipCode: selectedDireccion.zipCode,
        pay_payment_method: selectedMetodoPago.paymentMethodName || selectedMetodoPago.cardType,
        profileName: alias.trim(),
        shipping_addressId: selectedDireccion.addressId,
        shipping_addressLine: selectedDireccion.addressLine,
        shipping_attribute: selectedDireccion.attribute,
        shipping_city: selectedDireccion.city,
        shipping_country: "MX",
        shipping_firstName: selectedDireccion.firstName,
        shipping_lastName: selectedDireccion.lastName,
        shipping_modeId: shipModes?.find((shipMode) => shipMode?.shipModeCode === SHIPMODE.shipModeCode.PickUp)
          ?.shipModeId,
        shipping_nickName: selectedDireccion.nickName,
        shipping_state: selectedDireccion.state,
        shipping_zipCode: selectedDireccion.zipCode,
        userId: currentUser.userId,
      };
      if (isCard) {
        body = { ...body, ...savedCard };
      }
    }

    if (body) {
      dispatch(
        cprof.CPROF_UPDATE_ACTION({
          ...payloadBase,
          widget: "CheckoutProfileEdit",
          profileId: updateDataProfile.profile.xchkout_ProfileId,
          nickName: updateDataProfile.profile.xchkout_ProfileName,
          body,
        })
      );

      navigate(ROUTES.CHECKOUT_PROFILES);
    }
  };

  const validateEdit = () => {
    if (!mySite.isB2B) {
      return true;
    }
    let metodo = "";
    updateDataProfile && updateDataProfile?.profile?.protocolData?.map((data) => {
      if (data.name === "payment_method") {
        metodo = data.value;
      }
    });
    return (
      updateDataProfile?.profile?.xchkout_ProfileName !== alias ||
      selectedDireccion?.addressId !== updateDataProfile?.profile?.isValid?.addressId ||
      selectedMetodoPago?.paymentMethodName !== metodo
    );
  };

  if (!addressDetails || !misDirecciones) return null;

  // console.log("selectedMetodoPago", selectedMetodoPago?.cardType);
  // console.log("refHeights", refHeights);
  return (
    <>
      <StyledGrid item xs={12} md={3} className="sidebar styled--accountlinks-container">
        {isMobile ? null : <AccountSidebar />}
      </StyledGrid>

      <StyledGrid item xs={12} md={9} className="styled--profileCreate-container">
      {!isMobileApp && (
        <HDmHeadingTitle
          title={updateDataProfile ? t("CheckoutProfile.TitleEdit") : t("CheckoutProfile.PageTitleCreate")}
        />
      )}

        <StyledBox className="styled--alias-container">
          <StyledCardEmpty className="padding-2">
            <TitleCard title={t("CheckoutProfile.TitleCard")} alinear="left" />

            <StyledTextField
              id="alias"
              margin="normal"
              autoComplete="off"
              type="text"
              label={t("CheckoutProfile.profileNickName")}
              value={alias}
              name="searchTerm"
              onBlur={handleOnBlur}
              onChange={handleChanegeAlias}
              inputProps={{ maxLength: 100 }}
              disabled={false}
              error={errorAlias.error}
              helperText={errorAlias.errorMsg}
            />
          </StyledCardEmpty>
        </StyledBox>

        <StyledBox className="styled--direccionespagos-container">
          <DireccionDeEntrega
            updateDataProfile={updateDataProfile}
            addressDetails={addressDetails}
            misDirecciones={misDirecciones}
            handleSelectedDireccion={handleSelectedDireccion}
            handleGuardadoExito={handleGuardadoExito}
            refHeights={refHeights}
            setRefHeights={setRefHeights}
            selectedMetodoPago={selectedMetodoPago}
            isMobile={isMobile}
          />
          <MetodosDePago
            storeConfData={storeConfData}
            updateDataProfile={updateDataProfile}
            misDirecciones={misDirecciones}
            handleSelectedMetodoPago={handleSelectedMetodoPago}
            refHeights={refHeights}
            setRefHeights={setRefHeights}
            isMobile={isMobile}
            setSaveCard={setSaveCard}
            linkedAccount={linkedAccount} 
            setLinkedAccount={(linked) => setLinkedAccount(linked)}
            setListCardsLength={setListCardsLength}
          />
        </StyledBox>

        <StyledBox className="bottom-margin-2">
          <StyledCardEmpty className="styled--savecancelactions-container">
          {!mySite.isB2B && isMobile ?
            <StyledTypography variant="bodySubtext" className="color-gray300">
              { t("CheckoutProfile.youCanSelectMonthsOnPusrchase")}
            </StyledTypography>
            : null
            }
            <StyledBox className="vertical-padding-2">
              <StyledButton
                variant="contained"

                disabled={(selectedDireccion && selectedMetodoPago && isValidAlias && validateEdit() ? false : true ) || selectedDireccion?.zipCodeInBlocklist}


                onClick={updateDataProfile ? handleEditarPerfil : handleCrearProfile}>
                {t("CheckoutProfile.save")}
              </StyledButton>
            </StyledBox>
            <StyledBox align="center">
              <StyledLink to={ROUTES.CHECKOUT_PROFILES} className="styled--cancelCreate-link">
                {t("CheckoutProfile.cancel")}
              </StyledLink>
            </StyledBox>
          </StyledCardEmpty>
        </StyledBox>
      </StyledGrid>
    </>
  );
};

export default ProfileCreate;
