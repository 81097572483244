/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import { SKIP_WC_TOKEN_HEADER } from "../../../_foundation/constants/common";
//Custom libraries
import { FORGOT_PASSWORD, SIGNIN, SIGNUP, ORDER_CONFIRMATION, HOME } from "../../../constants/routes";
import { EMPTY_STRING } from "../../../constants/common";
import addressUtil from "../../../../src/utils/addressUtil";
//Redux
import * as userAction from "../../../redux/actions/user";
import { RESET_ERROR_ACTION } from "../../../redux/actions/error";
import {
  genericErrorSelector,
  passwordExpiredErrorSelector,
  sessionErrorSelector,
} from "../../../redux/selectors/error";
import { loginStatusSelector, rememberMeSelector } from "../../../redux/selectors/user";
//UI
import Divider from "@material-ui/core/Divider";
import {
  StyledTextField,
  StyledButton,
  StyledTypography,
  StyledPaper,
  StyledBox,
  StyledLink,
  StyledFormControlLabel,
  StyledCheckbox,
  StyledSwitch,
} from "../../../hdm";
import ButtonHDM from "../../custom-components/styled-cart-drawer/cart-item/ButtonHDM";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
//GA360
import AsyncCall from "../../../_foundation/gtm/async.service";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { StyledSignInContainer, StyledSignInEspotContainer } from "./SignInStyles";
import { InputAdornment, useMediaQuery, useTheme } from "@material-ui/core";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import RegisterBenefits from "./RegisterBenefits";
import { CLOSE_SIGN_IN_POPUP_ACTION, OPEN_REGISTER_POPUP_ACTION } from "../../../redux/actions/drawer";
import ForgotPassword from "../../pages/forgot-password";
import ForgotPasswordLayout from "../../pages/forgot-password/ForgotPasswordLayout";
import StyledWrapper from "../../StyledUI/StyledWrapper/StyledWrapper";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { LOGOUT_REQUESTED_ACTION, SESSION_ERROR_LOGIN_REQUESTED_ACTION } from "../../../redux/actions/user";
import CustomStepperLabel from "../../custom-components/shared/CustomStepperLabel";
import { SLSignInLayout } from "../../../components/custom-components/skeleton-loader/components/elements/SignIn"

//Storeconf
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { ProSignIn } from "./ProSignIn";

interface SessionErrorData {
  type?: string;
  bodyTitle?: string;
  rememberMeCopy?: string;
  buttonCopy?: string;
  showRememberMe?: boolean;
}
interface SignInContext {
  cid?: string;
  redirectRoute?: string;
  hideRegistrationPage?: any;
  isPopUp?: boolean;
  setTitle?: any;
  title?: string;
  closeHandler?: any;
  src?: SessionErrorData;
  errorTitleKey?: string;
  logonId?: string;
  sessionExpired?: boolean;
}

function SignInLayout({
  cid,
  hideRegistrationPage,
  isPopUp = false,
  setTitle = false,
  title = "",
  src = {},
  errorTitleKey,
  sessionExpired,
  ...props
}: SignInContext) {
  const widgetName = getDisplayName(SignInLayout);
  const passwordExpiredError: any = useSelector(passwordExpiredErrorSelector);
  const loginErrorMessage: any = useSelector<any>((state) => state.user.loginError);
  const genericError: any = useSelector<any>(genericErrorSelector);

  const isTokenRequired: any = useSelector<any>((state) => state.user.isTokenRequired); // is a state variable fetched from user.ts after submit
  const [isTokenRequiredSessionExpired, setTokenRequiredSessionExpired] = useState<boolean>(false);
  const isTokenInvalid: any = useSelector<any>((state) => state.user.isTokenInvalid);
  const isTokenExpired: any = useSelector<any>((state) => state.user.isTokenExpired);
  const [isTokenInvalidSessionExpired, setTokenInvalidSessionExpired] = useState<boolean>(false);
  // const installationData:any = useSelector<any>(state => state.hdmDataReducer);
  const closeHandler = props.closeHandler ? props.closeHandler : null;
  const { redirectRoute } = props;
  const loginStatus = useSelector(loginStatusSelector);

  const { mySite } = useSite();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [logonInputProps, setLogonInputProps] = useState<any>({});
  const [email, setEmail] = useState<string>(EMPTY_STRING);

  const [logonPassword, setLogonPassword] = useState<string>(EMPTY_STRING);
  const [logonPasswordNew, setLogonPasswordNew] = useState<string>(EMPTY_STRING);
  const [logonPasswordVerify, setLogonPasswordVerify] = useState<string>(EMPTY_STRING);
  const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING);
  const [verificationToken, setVerificationToken] = useState<string>(EMPTY_STRING);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [canContinuePro, setCanContinuePro] = useState<boolean>(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(true);

  const rememberMeFromSelector = useSelector(rememberMeSelector);

  //translations
  const logonIdLabel = t("SignIn.Label.B2B");
  const emailLabel = t("SignIn.Label.Email");
  const tokenLabel = t("SignIn.Label.VerificationToken");
  const invalidToken = t("SignIn.Msgs.InvalidToken");
  const expiredToken = t("SignIn.Msgs.ExpiredToken");
  const verificationTokenSubtitle = t("SignIn.VerificationTokenSubtitle");
  const verificationTokenReminder = t("SignIn.VerificationTokenReminder");
  const VerificationTokenResend = t("SignIn.VerificationTokenResend");
  const VerificationTokenVerify = t("SignIn.VerificationTokenVerify");
  const VerificationTokenNotReceived = t("SignIn.VerificationTokenNotReceived");
  const storeConfData = useSelector(onlineStoreRdc);
  const GOOGLE_RECAPTCHA_SCORE_BASED_SITE_KEY = storeConfData?.GOOGLE_RECAPTCHA_SCORE_BASED_SITE_KEY;
  const ENABLE_RECAPTCHA_LOGIN_VERIFICATION = storeConfData?.ENABLE_RECAPTCHA_LOGIN_VERIFICATION;
  const signInTitle = t("SignIn.SignInTitle");
  const forgotPasswordTitle = t("ForgotPassword.Title");

  const SESSION_ERROR = "SESSION_ERROR";

  const isB2B: boolean = mySite?.isB2B ? mySite.isB2B : false;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(960));

  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState<boolean>(false);

  const currentStoreData = useSelector(currentStoreSelector);

  const [showInputError, setShowInputError] = useState({
    emailError: false,
    passwordError: false,
    passwordVerifyError: false,
    isInputPasswordEmpty: false,
  });

  const { emailError, isInputPasswordEmpty } = showInputError;

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  // if (props.logonId && email === EMPTY_STRING) {
  // setEmail(props.logonId as any);
  // }

  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */
  const canContinue = (): boolean => {
    let flag: boolean = false;
    if (errorMessageKey) {
      if (
        email.trim() !== EMPTY_STRING &&
        logonPassword !== EMPTY_STRING &&
        logonPasswordNew !== EMPTY_STRING &&
        logonPasswordVerify !== EMPTY_STRING
      ) {
        const isEmail = isB2B ? true : addressUtil.validateEmail(email);
        flag = isEmail && logonPasswordNew.trim() === logonPasswordVerify.trim();
      }
    } else {
      if (email.trim() !== EMPTY_STRING && logonPassword !== EMPTY_STRING) {
        flag = isB2B ? true : addressUtil.validateEmail(email);
      }
    }
    return flag;
  };

  const canContinueTokenVerification = (): boolean => {
    let flag: boolean = true;
    if (email.trim() !== EMPTY_STRING && logonPassword !== EMPTY_STRING && verificationToken !== EMPTY_STRING) {
      const isEmail = isB2B ? true : addressUtil.validateEmail(email);
      const isToken = addressUtil.validateVerificationToken(verificationToken);
      flag = isToken && isEmail;
    }
    return flag;
  };

  const onRegisterClick = useCallback(() => {
    //hideRegistrationPage(false);
    navigate(SIGNUP);
  }, [hideRegistrationPage]);

  const isPopUpHandler = () => {
    dispatch(CLOSE_SIGN_IN_POPUP_ACTION({}));
    dispatch(OPEN_REGISTER_POPUP_ACTION({}));
  };

  useEffect(() => {
    // Add reCaptcha
    if (ENABLE_RECAPTCHA_LOGIN_VERIFICATION) {
      const existingScript = document.getElementById("googleRecaptcha");
      if (!existingScript) {
        const script = document.createElement("script");
        script.src =
          "https://www.google.com/recaptcha/enterprise.js?hl=es&render=" + GOOGLE_RECAPTCHA_SCORE_BASED_SITE_KEY;
        script.id = "googleRecaptcha";
        document.body.appendChild(script);
      }
    }
  });

  useEffect(() => {
    if (!isTokenRequired) {
      setShowRegistrationForm(true);
    }
  }, [isTokenRequired]);

  const onRememberMeChecked = useCallback((_event: any, value: boolean) => {
    setRememberMe(value);
  }, []);

  const handleSubmit = (props: any, resendToken: boolean = false) => {
    const isInputsValidated = canContinue();
    props.preventDefault();
    const payload = {
      body: {
        logonId: email,
        logonPassword,
        verificationToken: verificationToken,
        resendToken: resendToken,
      },
      query: {},
      physicalStoreId: currentStoreData?.currentStore?.stLocId,
      ...payloadBase,
    };
    if (rememberMe || rememberMeFromSelector) payload.query.rememberMe = rememberMe;

    // dispatch(userAction.LOGIN_REQUESTED_ACTION(payload));

    if (email.length < 1 && logonPassword.length < 1) {
      setShowInputError((prevState): any => ({ ...prevState, emailError: true, isInputPasswordEmpty: true }));
    } else if (logonPassword.length < 1) {
      setShowInputError((prevState): any => ({ ...prevState, emailError: emailError, isInputPasswordEmpty: true }));
    }

    if (isInputsValidated) {
      if (src?.type === SESSION_ERROR) {
        dispatch(userAction.RESET_LOGIN_TOKEN_ERROR());
        if (ENABLE_RECAPTCHA_LOGIN_VERIFICATION) {
          grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute(GOOGLE_RECAPTCHA_SCORE_BASED_SITE_KEY, {
              action: "LOGIN",
            });
            dispatch(
              SESSION_ERROR_LOGIN_REQUESTED_ACTION({
                body: {
                  logonId: email,
                  logonPassword: logonPassword,
                  verificationToken: verificationToken,
                  resendToken: resendToken,
                  grecaptchaToken: token,
                  isSessionExpired: "true",
                },
                skipErrorSnackbar: true,
                physicalStoreId: currentStoreData?.currentStore?.stLocId,
                ...payloadBase,
              })
            );
          });
        } else {
          dispatch(
            SESSION_ERROR_LOGIN_REQUESTED_ACTION({
              body: {
                logonId: email,
                logonPassword: logonPassword,
              },
              skipErrorSnackbar: true,
              physicalStoreId: currentStoreData?.currentStore?.stLocId,
              ...payloadBase,
            })
          );
        }
      } else {
        // if flag enabled
        if (ENABLE_RECAPTCHA_LOGIN_VERIFICATION) {
          grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute(GOOGLE_RECAPTCHA_SCORE_BASED_SITE_KEY, {
              action: "LOGIN",
            });
            payload.body.grecaptchaToken = token;
            dispatch(userAction.LOGIN_REQUESTED_ACTION(payload));
          });
        } else {
          dispatch(userAction.LOGIN_REQUESTED_ACTION(payload));
        }
        setShowRegistrationForm(false);
      }
    }

    if (location.pathname === ORDER_CONFIRMATION) {
      navigate(HOME);
    }
  };
  const handleLogonAndChangePasswordSubmit = (props: any) => {
    props.preventDefault();
    dispatch(
      userAction.LOGON_AND_CHANGE_PASSWORD_ACTION({
        body: {
          logonId: email,
          logonPassword,
          logonPasswordNew,
          logonPasswordVerify,
        },
        [SKIP_WC_TOKEN_HEADER]: true,
        skipErrorSnackbar: true,
        ...payloadBase,
      })
    );
  };
  const handleLogout = () => {
    const param: any = {
      ...payloadBase,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
  };
  const goToSignInPage = () => {
    if (src?.type === SESSION_ERROR) {
      handleLogout();
      if (closeHandler !== null) {
        closeHandler();
        return;
      }
    }
    dispatch(RESET_ERROR_ACTION());
    navigate(SIGNIN);
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => (prev ? false : true));
  };

  useEffect(() => {
    if (mySite) {
      if (mySite.isB2B) {
        setLogonInputProps({
          maxLength: 100,
          type: "text",
        });
      } else {
        setLogonInputProps({
          maxLength: 100,
          type: "email",
          placeholder: "correo@dominio.com",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  //This code is commented on purpose, this enables the 'password expired' view, in case is needed just enable it
  /*
  useEffect(() => {
    if (passwordExpiredError.errorKey && passwordExpiredError.errorCode) {
      setErrorMessageKey("error-message." + passwordExpiredError.errorKey + "_" + passwordExpiredError.errorCode);
      setLogonPassword(EMPTY_STRING);
      setLogonPasswordNew(EMPTY_STRING);
      setLogonPasswordVerify(EMPTY_STRING);
    } else {
      setErrorMessageKey(EMPTY_STRING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordExpiredError]);
*/

  useEffect(() => {
    if (
      (window.location.pathname.indexOf("sign-in") != -1 || window.location.pathname.indexOf(SIGNIN) != -1) &&
      window.setOpenMiniSingIn
    ) {
      hideRegistrationPage(false);
    }
  }, []);

  useEffect(() => {
    console.log("reset token required " + isTokenRequired);
    console.log("reset token incorrect " + isTokenInvalid);
    return () => {
      dispatch(userAction.RESET_LOGIN_TOKEN_ERROR());
      console.log("reset token required " + isTokenRequired);
      console.log("reset token incorrect " + isTokenInvalid);
    };
  }, []);

  useEffect(() => {
    genericError?.errorCode === "8000" && setTokenRequiredSessionExpired(true);
    genericError?.errorCode === "8001" && setTokenInvalidSessionExpired(true);
    genericError?.errorCode === "8000" && setShowRegistrationForm(false);
    !loginError && setLoginError(genericError?.errorCode ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericError]);

  useEffect(() => {
    if (props.logonId) {
      setEmail(props.logonId as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCanContinuePro(logonPassword?.length > 0 && email?.length > 0 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logonPassword, email]);

  // if (props.logonId && email === EMPTY_STRING) {
  // setEmail(props.logonId as any);
  // }

  const handleEmail = (e) => {
    setLoginError(false);
    setEmail(e.target.value);
  };
  const pwSetter = (e) => {
    setLoginError(false);
    setLogonPassword(e.target.value);
    if (logonPassword.length > 1) {
      setShowInputError((prevState): any => ({ ...prevState, isInputPasswordEmpty: false }));
    }
  };

  const handleForgotPasswordClick = (e) => {
    if (setTitle) {
      setTitle(forgotPasswordTitle);
    } else {
      navigate("/forgot-password");
    }

    setShowForgotPasswordForm(true);
  };

    // return <SLSignInLayout/>

  return (
    <>
      {!isB2B ? 
        <>
           <StyledSignInContainer isMobile={isMobile} isPopUp={isPopUp}>
            <StyledWrapper>
          {showForgotPasswordForm ? (
            <ForgotPasswordLayout
              isPopUp={isPopUp}
              closeHandler={props.closeHandler}
              setShowForgotPasswordForm={setShowForgotPasswordForm}
              setTitle={setTitle}
            />
          ) : errorMessageKey ? (
            <>
              <StyledTypography component="h1" variant="h4" className="bottom-margin-1">
                {t("SignIn.ChangPassword")}
              </StyledTypography>
              <StyledTypography component="p" className="error">
                {t(errorMessageKey)}
              </StyledTypography>
              <form noValidate name="changePasswordForm" onSubmit={handleLogonAndChangePasswordSubmit}>
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  name="password"
                  label={t("SignIn.CurrentPassword")}
                  onChange={(e) => setLogonPassword(e.target.value)}
                  value={logonPassword}
                  inputProps={{
                    maxLength: 100,
                    type: "password",
                  }}
                />
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={t("SignIn.Password")}
                  type="password"
                  placeholder={EMPTY_STRING}
                  name="logonPasswordNew"
                  maxLength="100"
                  value={logonPasswordNew}
                  onChange={(e) => setLogonPasswordNew(e.target.value)}
                />
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={t("SignIn.PasswordVerify")}
                  type="password"
                  placeholder={EMPTY_STRING}
                  name="logonPasswordVerify"
                  value={logonPasswordVerify}
                  maxLength="100"
                  onChange={(e) => setLogonPasswordVerify(e.target.value)}
                  error={
                    logonPasswordVerify.trim().length > 0 && logonPasswordNew.trim() !== logonPasswordVerify.trim()
                  }
                  helperText={
                    logonPasswordVerify.trim().length > 0 && logonPasswordNew.trim() !== logonPasswordVerify.trim()
                      ? t("SignIn.Msgs.PasswordNotMatch")
                      : EMPTY_STRING
                  }
                />
                <StyledBox mt={2}>
                  <StyledButton testId="sign-in-submit" color={"primary"} type="submit" disabled={!canContinue()}>
                    {t("SignIn.Submit")}
                  </StyledButton>
                  <StyledButton
                    testId="sign-in-cancel"
                    color="secondary"
                    style={{ float: "right" }}
                    onClick={goToSignInPage}>
                    {t("SignIn.Cancel")}
                  </StyledButton>
                </StyledBox>
              </form>
            </>
          ) : (
            <>
              {/* Login title */}
              {!isPopUp && (
                <>
                  <StyledBox className={isB2B ? "bottom-margin-4" : ""} sx={{ display: "flex" }}>
                    <StyledTypography
                      component="h1"
                      variant="headingSubhead"
                      className={"bottom-margin-1 primary-color-divider"}>
                      {signInTitle}
                    </StyledTypography>
                    <StyledBox className="gray-color-divider" />
                  </StyledBox>
                  {isB2B ? (
                    <>
                      <StyledBox className="bottom-margin-2">
                        <CustomStepperLabel stepNumber={1} sideText={t("SignInPage.ProLabels.LabelStepperSideText")} />
                      </StyledBox>
                    </>
                  ) : null}
                </>
              )}

              {src?.type === SESSION_ERROR && !isTokenRequired && !isTokenRequiredSessionExpired && (
                <StyledTypography variant="bodyBaseLine" className={"bottom-margin-1"}>
                  {src?.bodyTitle}
                </StyledTypography>
              )}

              {(showRegistrationForm || !isTokenRequired) && !isTokenRequiredSessionExpired ? (
                <form onSubmit={handleSubmit} noValidate>
                  <StyledTextField
                    className="bottom-margin-2"
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    name="email"
                    autoComplete="email"
                    label={emailLabel}
                    autoFocus
                    onChange={handleEmail}
                    value={email}
                    inputProps={logonInputProps}
                    error={emailError}
                    onBlur={() =>
                      setShowInputError((prevState): any => ({
                        ...prevState,
                        emailError: !addressUtil.validateEmail(email) ? true : false,
                      }))
                    }
                    helperText={emailError && t("SignIn.Msgs.InvalidFormat")}
                    // helperText={!addressUtil.validateEmail(email) && !isB2B ? t("SignIn.Msgs.InvalidFormat") : EMPTY_STRING}
                  />
                  <StyledTextField
                    variant="outlined"
                    margin="normal"
                    className="bottom-margin-3"
                    // required
                    fullWidth
                    name="password"
                    autoComplete="current-password"
                    label={t("SignIn.Password")}
                    onChange={pwSetter}
                    value={logonPassword}
                    inputProps={{
                      maxLength: 100,
                      type: showPassword ? "" : "password",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon onClick={handleShowPassword} className="show-password-icon" />
                          ) : (
                            <VisibilityOutlinedIcon onClick={handleShowPassword} className="show-password-icon" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      loginError && logonPassword.length > 0
                        ? t(`${isB2B ? "SignIn.Msgs.InvalidCredentialsPro" : "SignIn.Msgs.InvalidCredentials"}`)
                        : isInputPasswordEmpty
                        ? t(`${isB2B ? "SignIn.Msgs.InvalidCredentialsPro" : "SignIn.Msgs.InvalidCredentials"}`)
                        : ""
                    }
                    error={loginError && logonPassword.length > 0 ? true : isInputPasswordEmpty ? true : false}
                    end
                  />
                  {process.env.REACT_APP_PERSISTENT_SESSION?.toLowerCase() === "true" && (
                    <>
                      {(src?.type !== SESSION_ERROR || (src?.type === SESSION_ERROR && src?.showRememberMe)) && (
                        <StyledFormControlLabel
                          className={isB2B ? "bottom-margin-2" : "vertical-padding-1"}
                          control={<StyledSwitch checked={rememberMe} color="primary" onChange={onRememberMeChecked} />}
                          label={t("SignIn.Label.rememberMe")}
                        />
                      )}

                      {src?.type === SESSION_ERROR && src?.showRememberMe && (
                        <StyledTypography variant="bodySubText" className={"color-gray200 bottom-margin-2"}>
                          {src?.rememberMeCopy}
                        </StyledTypography>
                      )}
                      {src?.type === SESSION_ERROR && !src?.showRememberMe && (
                        <div className="vertical-padding-2"></div>
                      )}
                    </>
                  )}
                  <StyledBox align="left">
                    <StyledButton
                      testId="sign-in-submit"
                      type="submit"
                      color="primary"
                      disabled={isB2B ? !canContinuePro : false}
                      className={`login-process-button ${isB2B ? "bottom-margin-4" : "bottom-margin-2"}`}>
                      {t(`${isB2B ? "SignInPage.ProLabels.ContinueButtonLabel" : "SignIn.SignInButton"}`)}
                    </StyledButton>
                    {src?.type === SESSION_ERROR && (
                      <StyledGrid container justifyContent="center">
                        <StyledGrid item>
                          <StyledTypography
                            variant="bodyBaseline"
                            color="primary"
                            className="link vertical-margin-1"
                            onClick={goToSignInPage}>
                            {src?.buttonCopy}
                          </StyledTypography>
                        </StyledGrid>
                      </StyledGrid>
                    )}
                    {src?.type !== SESSION_ERROR && (
                      <StyledTypography
                        variant="bodyBaseline"
                        color="primary"
                        className={`forgot-password-link vertical-margin-1 ${
                          isB2B ? "bottom-margin-2" : "bottom-margin-3"
                        } link`}
                        onClick={isB2B ? "" : handleForgotPasswordClick}>
                        {t("SignIn.ForgotPassword")}
                      </StyledTypography>
                    )}

                    {!isB2B && src?.type !== SESSION_ERROR && (
                      <>
                        <Divider className="vertical-margin-2 divider" />
                        <StyledTypography
                          variant="bodyBaseline"
                          className="bottom-margin-2  top-margin-3 no-account-title">
                          {t("SignIn.noAccount")}
                        </StyledTypography>
                        <RegisterBenefits />
                        <ButtonHDM
                          variant="outlined"
                          text={t("SignIn.registerNow")}
                          onClick={isPopUp ? isPopUpHandler : onRegisterClick}
                        />
                      </>
                    )}

                    {!isB2B && ENABLE_RECAPTCHA_LOGIN_VERIFICATION && src?.type !== SESSION_ERROR && (
                      <>
                        <Divider className="vertical-margin-2 divider" />
                        <StyledTypography
                          variant="bodyBaseline"
                          style={{ lineHeight: 1.4, fontSize: "0.8em", color: "#999", marginBottom: 10 }}>
                          Este sitio está protegido por RECAPTCHA Enterprise, aplican{" "}
                          <a
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            className=" vertical-margin-1 bottom-margin-3 link">
                            Política de privacidad
                          </a>{" "}
                          y{" "}
                          <a
                            href="https://policies.google.com/terms"
                            target="_blank"
                            className=" vertical-margin-1 bottom-margin-3 link">
                            Términos de Servicio
                          </a>{" "}
                          de Google
                        </StyledTypography>
                      </>
                    )}
                  </StyledBox>
                </form>
              ) : (
                <form>
                  <StyledGrid container justifyContent="center" alignItems="center" spacing={1}>
                    <StyledGrid item xs={12}>
                      <StyledTypography
                        variant="bodyBaseline"
                        weight="regular"
                        className={"bottom-margin-1 vertical-margin-1"}>
                        {verificationTokenSubtitle}
                      </StyledTypography>
                    </StyledGrid>
                    <StyledGrid item className={"vertical-margin-1"}>
                      <img
                        src="https://cdn.homedepot.com.mx/assets/img/reset-password/send-mail.svg"
                        alt="https://cdn.homedepot.com.mx/assets/img/reset-password/send-mail.svg"
                        width={isMobile ? "100" : "150"}
                        className="lazyload"></img>
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <StyledTypography
                        variant="bodyBaseline"
                        weight="regular"
                        className="color-gray200 bottom-margin-1 vertical-margin-1">
                        {verificationTokenReminder}
                      </StyledTypography>
                    </StyledGrid>
                  </StyledGrid>

                  <StyledTextField
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    name="email"
                    autoComplete="email"
                    label={mySite.isB2B ? logonIdLabel : emailLabel}
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    inputProps={logonInputProps}
                    error={emailError}
                    onBlur={() =>
                      setShowInputError((prevState): any => ({
                        ...prevState,
                        emailError: !addressUtil.validateEmail(email) ? true : false,
                      }))
                    }
                    helperText={emailError && t("SignIn.Msgs.InvalidFormat")}
                    // helperText={!addressUtil.validateEmail(email) && !isB2B ? t("SignIn.Msgs.InvalidFormat") : EMPTY_STRING}
                    disabled
                  />

                  <StyledTextField
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    name="verificationToken"
                    autoComplete="verificationToken"
                    label={tokenLabel}
                    autoFocus
                    isToken
                    inputProps={{
                      maxLength: 6,
                    }}
                    onChange={(e) => setVerificationToken(e.target.value)}
                    value={verificationToken}
                    // inputProps={logonInputProps}
                    error={isTokenInvalid || isTokenInvalidSessionExpired || isTokenExpired}
                    onBlur={() =>
                      setShowInputError((prevState): any => ({
                        ...prevState,
                        tokenError: !addressUtil.validateVerificationToken(verificationToken) ? true : false,
                      }))
                    }
                    helperText={
                      isTokenInvalid || isTokenInvalidSessionExpired ? invalidToken : isTokenExpired && expiredToken
                    }
                  />

                  <StyledBox mt={2}>
                    <StyledButton
                      testId="sign-in-submit"
                      type="submit"
                      color="primary"
                      disabled={canContinueTokenVerification()}
                      className="login-process-button bottom-margin-2"
                      onClick={handleSubmit}>
                      {VerificationTokenVerify}
                    </StyledButton>

                    <StyledTypography variant="bodyBaseline" weight="regular" className="bottom-margin-1">
                      {VerificationTokenNotReceived}
                    </StyledTypography>

                    {/* <ButtonHDM variant="outlined" text={VerificationTokenResend} /> */}
                    <StyledButton
                      testId="reset-password-resend-verification-code"
                      variant="outlined"
                      type="button"
                      className="login-process-button"
                      onClick={(e) => handleSubmit(e, true)}>
                      {VerificationTokenResend}
                    </StyledButton>
                  </StyledBox>
                </form>
              )}
            </>
          )}
        </StyledWrapper>
      </StyledSignInContainer>

      {!isPopUp && (
        <StyledSignInEspotContainer isMobile={isMobile}>
          <EspotHdm espotName={isB2B ? "ProLogIn-AdditionalInfo" : "Login_AdditionalInfo"} />
        </StyledSignInEspotContainer>
      )}
    </>
    :
    <>
    {console.log("sessionExpiredQQ", sessionExpired)}
    {console.log("title", title)}
      <ProSignIn isPopUp={isPopUp} title={title} setTitle={setTitle} errorTitleKey={errorTitleKey} closeHandler={closeHandler} src={src} sessionExpired={sessionExpired} />
    </>
    }
    </> 
  );
}

export { SignInLayout };
