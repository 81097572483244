import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";


export const SccProductViewContainer = styled((props: any) => <Grid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    .prompt-container{
        border: solid  ${theme.palette.highlight.main} 1px;
        border-left: solid 5px ${theme.palette.highlight.main};
        margin-top: ${theme.spacing(2)}px;
        margin-right: ${theme.spacing(1)}px;
        padding: ${theme.spacing(1)}px;
        display: flex;
    }

    .paid-icon{
        color: ${theme.palette.highlight.main};
        font-size: 11px;
        margin-right: ${theme.spacing(1)}px;
    }

    .prompt-text{
        color: ${theme.palette.highlight.main};
    }
    `}
`;