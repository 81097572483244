/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
 import React, { useEffect, useRef, useState } from "react";
 import axios from "axios";
 
 //UI
 import { StyledBox, StyledCardEmpty, StyledGrid, StyledTypography } from "../../elements";
 
 // Icon
 import LogoHDM from "../../../../src/assets/homedepot/images/the-home-depot-gray.svg";
 
 // Add to cart imports
 import { useMemo } from "react";
 import { useDispatch, useSelector } from "react-redux";
 import { currentContractIdSelector } from "../../../redux/selectors/contract";
 
 import { useSite } from "../../../_foundation/hooks/useSite";
 import * as orderActions from "../../../redux/actions/order";
 import Axios, { Canceler } from "axios";
 import getDisplayName from "react-display-name";
 import { Divider } from "@material-ui/core";
 // import { OPEN_CART_DRAWER_ACTION } from "../../../redux/actions/drawer";
 
 // Responsive imports
 import { useTheme } from "@material-ui/core/styles";
 import useMediaQuery from "@material-ui/core/useMediaQuery";
 
 // Helpers
 import { currentStoreSelector } from "../../../redux/selectors/current-store";
 import * as customInv from "../../../_foundation/utils/inventoryAvailability";
 
 import {
   installationPartNumber,
   onlineStoreRdc,
   hdmStoresDataRdc,
   hdmEmarsysPDPTagFinished,
   hdmStoreBuyableExclude,
   guestUserDataSelector,
   hdmPDPproductDataRdc,
 } from "../../../redux/selectors/hdmData";
 import { getPromoicons, numberWithCommas } from "../../functions";
 import styled from "styled-components";
 import { MerchandisingAssociationProductInfo } from "./MerchandisingAssociationProductInfo";
 import { MerchandisingAssociationTotalPrice } from "./MerchandisingAssociationTotalPrice";
 import { MerchandisingAssociationImages } from "./MerchandisingAssociationImages";
 import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
 import { EMPTY_STRING } from "../../../constants/common";
 import productsService from "../../../_foundation/apis/search/products.service";
 import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { loginStatusSelector, logonIdSelector, userIdSelector } from "../../../redux/selectors/user";
 import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
 import { orderItemsSelector } from "../../../redux/selectors/order";
 // import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import * as CryptoJS from 'crypto-js'; 
import { getProductsPrice } from "../../../redux/sagas/workers/catalog";

 const ContainerStyledMerchandising = styled(({ ...props }) => <StyledBox {...props} />)`
   ${({ theme, color }) => `
 
   &.styled--merchandising-container {
     margin-bottom: 10px;
     ${theme.breakpoints.down(1024)} {
       margin-right: 10px;
       margin-left: 10px;
     }
   }
   
   .styled--merchandisingcard-container {
     padding: 10px;
 
   }
 
   .styled--productimages-container {
     width: 100%;
     display: flex;
     align-items: start;
   }
   
   .styled--product-images {
     display: flex;
     align-items: center;
     width: 100%;
   }
 
   .styled--allproducts-container {
     display: flex;
     flex-direction: column;
     ${theme.breakpoints.up(960)} {
       flex-direction: row;
     }
     
   }
 
   .styled--totalproduct-container {
     display: flex;
     width: 100%;
   }
 
   .styled-imageprice-container {
     display: flex;
     ${theme.breakpoints.down(1024)} {
       flex-direction: column;
     }
     
   }
 
 `}
 `;
 
 interface MerchandisingAssociationContentProps {
   slides: any;
   recommendedProdTitle: string;
 }
 
 /**
  * Merchandising association display widget.
  *
  * ` @prop { any } props` have following properties:
  * ` @property { any } slides (required)` The list of merchandise associated products.
  * ` @property { string } recommendedProdTitle (required)' The title of merchandise associated products.
  *
  * MerchandisingAssociationContent returns all the list of merchandise associated products.
  */
 
 const MerchandisingAssociationContent: React.FC<MerchandisingAssociationContentProps> = (props: any) => {
 
   const slides = props.principalProduct[0]?.merchandisingAssociations?.filter((product) => product?.associationType !== "Comparar" && !product?.partNumber.includes("SI"));
   const recommendedProdTitle = props.recommendedProdTitle;
   const originalProduct = props.principalProduct[0];
   
   // Add to cart data
   const { mySite } = useSite();
   const contract = useSelector(currentContractIdSelector);
   const CancelToken = Axios.CancelToken;
   const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
   const widgetName = getDisplayName("ProductDetailsWidget");
   const storeConfData = useSelector(onlineStoreRdc);
   const cdnUrl = storeConfData?.IMAGE_SERVER_HOSTNAME + "productos/";
   const isB2B = Boolean(mySite?.isB2B);
   const loginStatus = useSelector(loginStatusSelector);
   const proCustomerLevel = useSelector(proCustomerLevelSelector);
 
   /*----------- EMARSYS ________________*/
   /* Current store */
   const storeSelector = useSelector(currentStoreSelector);
   const currentStoreId = storeSelector.currentStore.stLocId;
   const currentMarketId = storeSelector.currentStore.marketId;
 
   /* States */
   const [emarsysFetchedData, setEmarsysFetchedData] = useState<any[any]>([]);
   const [loadingScript, setLoadingScript] = useState<boolean>(false);
   const [selectedProducts, setSelectedProducts] = useState<any>(null);
   const [productsReady, setProductsReady] = useState<boolean>(false);
   // const [numberOfSelectedProducts, setNumberOfSelectedProducts] = useState<number>(selectedProducts?.length);
   const [numberOfSelectedProducts, setNumberOfSelectedProducts] = useState<number>(0);
   const hdmOrderItemsSelector = useSelector(orderItemsSelector);
   const hdmPDPproductData = useSelector(hdmPDPproductDataRdc);
 
 
   const email = useSelector(logonIdSelector);
   /* Ref to the container element that stores the emarsys products */
   const emarsysRef = useRef<HTMLInputElement | null>(null);
   const storeBuyableExclude = useSelector(hdmStoreBuyableExclude);
   const [seconds, setSeconds] = useState<any>(null);
 
   const installationPartNumberData = useSelector(installationPartNumber);
   const hdmStoresData = useSelector(hdmStoresDataRdc);
   const emarsysPDPTagFinished = useSelector(hdmEmarsysPDPTagFinished);
   const breadcrumbsValue = useSelector(breadcrumbsSelector);
   const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();
  const sha256value = (userIdVal) => {
    return CryptoJS.SHA256(userIdVal).toString(CryptoJS.enc.Hex);
  };

   const payloadBase: any = {
     storeId: mySite.storeID,
     widget: widgetName,
     cancelToken: new CancelToken(function executor(c) {
       cancels.push(c);
     }),
   };
 
   useEffect(() => {
     const secondsNow: any = new Date().getTime() / 1000;
     setSeconds(parseInt(secondsNow));
     return () => {
       setSeconds(null);
     };
   }, []);
 
   /* Emarsys script */
   useEffect(() => {
     if (
       (!slides || slides?.length === 0) &&
       breadcrumbsValue &&
       breadcrumbsValue?.length !== 0 &&
       emarsysPDPTagFinished &&
       emarsysPDPTagFinished === "product-page" &&
       hdmPDPproductData?.storeInventory &&
       seconds &&
       currentStoreId &&
       currentMarketId &&
       !isB2B
       ) {
       EmarsysScript();
     }
 
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentStoreId, currentMarketId, loadingScript, emarsysPDPTagFinished, breadcrumbsValue, hdmPDPproductData?.storeInventory]);
 
   useEffect(() => {
 
     if (
       (!slides || slides?.length === 0) &&
       breadcrumbsValue &&
       breadcrumbsValue?.length !== 0 &&
       hdmPDPproductData?.storeInventory &&
       seconds &&
       currentStoreId &&
       currentMarketId &&
       isB2B
     ) {
       EmarsysScript();
     } 
 
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentStoreId, currentMarketId, loadingScript, breadcrumbsValue, hdmPDPproductData?.storeInventory]);
 
   const EmarsysScript = async () => {
     const promociones = getPromoicons(originalProduct?.attributes, isB2B, currentStoreId, currentMarketId);
     if (promociones.length > 0){
       if(isB2B && (promociones?.includes("PRO_STORE_ONLY_AVAILABLE") || promociones?.includes("PRO_STORE_BUYABLE_EXCLUDE"))){
         return
       }else if(promociones?.includes("STORE_ONLY_AVAILABLE") || promociones?.includes("STORE_BUYABLE_EXCLUDE")){
         return
       }
     }
 
     const ScarabQueue: any = window["ScarabQueue"];
     const baseProductSKU = originalProduct?.items ? originalProduct.items[0].partNumber : originalProduct.partNumber;
 
     let categories = "";
     const arrayCategories = breadcrumbsValue.filter((e) => e.type_ === "FACET_ENTRY_CATEGORY");
     arrayCategories.map((tag) => {
       categories += tag.label + ">";
     });
     categories = categories.substring(0, categories.length - 1);
 
     if(!isB2B){
       await new Promise((resolve) => setTimeout(resolve, 2000));
     }
     ScarabQueue.push([
       "recommend",
       {
         logic: `ALSO_BOUGHT_${currentStoreId}`,
         limit: 10,
         containerId: `emarsys_bundle_items_${seconds}`,
         success: async function (SC) {
           if (SC?.page?.products && SC?.page?.products?.length !== 0) {
             const products: any = [];
 
             SC?.page?.products.map((prod) => {
               products.push({
                 partNumber: prod.item,
               });
             });
 
             getProductDetailsByPartnumber(products);
             // setEmarsysFetchedData(products)
             setEmarsysFetchedData(products.slice(0, 2))
           }
           if(!isB2B){
             const query = {
               url: "",
               data: null,
               option: "EMARSYS_PDP_TAG_FINISHED",
               fetch: false,
             };
             dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
           }
         },
       },
     ]);
     ScarabQueue.push(["view", baseProductSKU]);
     ScarabQueue.push(["exclude", "category", "has", categories]);
     
     if(loginStatus && !isB2B){
      if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
        && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
          if (storeConfData?.ENABLE_EMARSYS_TEST_MODE !== undefined
            && storeConfData?.ENABLE_EMARSYS_TEST_MODE === 'true') {
              ScarabQueue.push(['testMode']);
            }
        ScarabQueue.push(["setCustomerId", sha256value(userId)]);
      } else {
      ScarabQueue.push(["setEmail", email]);
     }
    }
     if(!isB2B){
       ScarabQueue.push(["go"]);
     }else {
       const query = {
         url: "",
         data: true,
         option: "EMARSYSBUNDLE_ITEMS_READY",
         fetch: false,
       };
       dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
     }
 
   };
 
   /*----- Initial States -----*/
 
   // Responsive
   const theme = useTheme();
   // const isMobile = useMediaQuery(theme.breakpoints.down(960));
   const isTablet = useMediaQuery(theme.breakpoints.down(1024));
   const isDesktop = useMediaQuery(theme.breakpoints.up(960));
 
   useEffect(() => {
     if (slides && slides.length > 0 && originalProduct && hdmStoresData && hdmPDPproductData?.storeInventory) {
       const promociones = getPromoicons(originalProduct?.attributes, isB2B, currentStoreId, currentMarketId);
       if (promociones.length > 0){
         if(isB2B && (promociones?.includes("PRO_STORE_ONLY_AVAILABLE") || promociones?.includes("PRO_STORE_BUYABLE_EXCLUDE"))){
           return
         }else if(promociones?.includes("STORE_ONLY_AVAILABLE") || promociones?.includes("STORE_BUYABLE_EXCLUDE")){
           return
         }
       }
 
       getProductDetailsByPartnumber(slides);
     }
   }, [originalProduct, hdmStoresData, hdmPDPproductData?.storeInventory]);
 
   useEffect(() => {
     let newNominalQuantity: any = "1";
     if (hdmOrderItemsSelector && productsReady) {
 
       const selectedProductsClone = JSON.parse(JSON.stringify(selectedProducts))
       selectedProductsClone.map(prod => {
         const productInCart = hdmOrderItemsSelector.find(orderItem => orderItem?.partNumber === prod?.partNumber);
 
         if(productInCart){
           newNominalQuantity = parseInt(prod?.nominalQuantity) - parseInt(productInCart?.quantity);
           if (newNominalQuantity <= 0) {
             prod.buyQuantity = "1"
             prod["x_measurements.nominalQuantity"] = "1"
           } else {
             prod.buyQuantity = prod.nominalQuantity;
             prod["x_measurements.nominalQuantity"] = prod.nominalQuantity
           }
 
         }else {
           prod.buyQuantity = prod.nominalQuantity;
           prod["x_measurements.nominalQuantity"] = prod.nominalQuantity
         }
 
       })
 
       setSelectedProducts(selectedProductsClone)
     } 
   }, [hdmOrderItemsSelector, productsReady]);
 
   const getMarketDisponibilidad = async (productIds) => {
     const storeUniqueId = hdmStoresData.find((store) => store.stLocId === currentStoreId);
 
     const parameters: any = {
       ...payloadBase,
       productIds: productIds,
     };
 
     const disponibilidad = await customInv.getAvailabilityByStores({
       ...parameters,
       onlineStoreId: mySite.storeID,
       productavailable: true,
       fullfillment_type: "Store",
       type: "ItemBean",
       search: 1,
       physicalStoreId: storeUniqueId ? [storeUniqueId?.uniqueID] : [""], //["12505", "12521"]
     });
 
     if (disponibilidad && disponibilidad?.length !== 0) {
       return disponibilidad
     }
     // if (disponibilidad && disponibilidad?.length !== 0) {
     //   if(productIds.length === 1){
     //     return disponibilidad.find((disp) => disp?.physicalStoreId === storeUniqueId?.uniqueID);
         
     //   }else if(productIds.length > 1){
     //     return disponibilidad
     //   }
     // }
 
     return null;
   };
 
   const getProductDetailsByPartnumber = async (dataSlides) => {
     // const disponibilidadMarket: any = await getMarketDisponibilidad([originalProduct?.id]);
 
     const disponibilidadMarket: any = hdmPDPproductData?.storeInventory;
     let marketQuantity = 0;
     if (disponibilidadMarket && disponibilidadMarket?.length !== 0 && disponibilidadMarket[0].inventoryStatus !== "Unavailable") {
       
       marketQuantity = disponibilidadMarket[0]?.x_AvailableQuantityInMarket ? parseInt(disponibilidadMarket[0]?.x_AvailableQuantityInMarket) : 0;
     }
     
     if (marketQuantity === 0) return;
 
     let partNumbers = "";
 
     dataSlides.map((dataSlide) => {
       partNumbers = `${partNumbers}&partNumber=${dataSlide?.partNumber}`;
     });
 
     await Axios.get<any>(
       `/search/resources/api/v2/products?storeId=${mySite.storeID}${partNumbers}&contractId=${contract}&langId=-5&physicalStoreId=${currentStoreId}`
     )
       .then(async (response) => {
 
         if (response && response.status === 200 && response?.data?.contents) {
           const products: any = [];
           const productsIdInventory: any = []
 
             const allProducts = response.data.contents
             allProducts.map(async (response) => {
               const promociones = getPromoicons(response?.attributes, isB2B, currentStoreId, currentMarketId);
 
               if (promociones.length > 0){
                 if(isB2B && (promociones?.includes("PRO_STORE_ONLY_AVAILABLE") || promociones?.includes("PRO_STORE_BUYABLE_EXCLUDE") || promociones?.includes("PRO_STORE_ONLY_AVAILABLE"))){
                   return
                 }else if(promociones?.includes("STORE_ONLY_AVAILABLE") || promociones?.includes("STORE_BUYABLE_EXCLUDE") || promociones?.includes("STORE_ONLY_AVAILABLE")){
                   return
                 }
               }
               productsIdInventory.push(response?.id)
 
             })
 
             if(productsIdInventory.length !== 0){
               const disponibilidadMarket: any = await getMarketDisponibilidad(productsIdInventory);
             disponibilidadMarket.map(inv => {
               if(inv?.x_AvailableQuantityInMarket && parseInt(inv?.x_AvailableQuantityInMarket) > 0 ){
                const selectedProduct = allProducts.find(prod => prod?.id === inv?.productId);
                if(parseFloat(selectedProduct.price[0].value) >0 && parseFloat(selectedProduct.price[1].value)>0){
                  selectedProduct.isSelected = true;
                  selectedProduct.buyQuantity = parseInt(selectedProduct["x_measurements.nominalQuantity"])
                  selectedProduct.nominalQuantity = parseInt(selectedProduct["x_measurements.nominalQuantity"])
                  products.push(selectedProduct)
                }
              }
 
             })
           }
 
           const cloneOriginalProduct = JSON.parse(JSON.stringify(originalProduct));
           delete cloneOriginalProduct.merchandisingAssociations;
           cloneOriginalProduct.isSelected = true;
           cloneOriginalProduct.buyQuantity = parseInt(cloneOriginalProduct["x_measurements.nominalQuantity"])
           cloneOriginalProduct.nominalQuantity = parseInt(cloneOriginalProduct["x_measurements.nominalQuantity"])
           products.unshift(cloneOriginalProduct);

           if(isB2B && loginStatus && products?.length > 0){
             const allProPrices = await getProductsPrice({
              products: products,
              data: {
                currentStoreId: currentStoreId,
                currentMarketId: currentMarketId,
                isB2B: mySite.isB2B
              }
              })
           }
 
           setSelectedProducts(products.slice(0, 3));
           setNumberOfSelectedProducts(products?.slice(0, 3).length)
           setProductsReady(true)
         }
       })
       .catch((error) => {
         console.log("error", error);
       });
   };
 
   const handleOnChange = (target) => {
     const checkState = target.checked;
     const value = target.value;
 
     if (checkState) {
       const products = JSON.parse(JSON.stringify(selectedProducts));
       products.map((product) => {
         if (product?.partNumber === value) {
           const actualProductQuantity = product.buyQuantity
           product.isSelected = true;
           product.buyQuantity = "1";
           setNumberOfSelectedProducts( prev => prev + 1);
         }
       });
 
       setSelectedProducts(products);
     } else {
       const products = JSON.parse(JSON.stringify(selectedProducts));
       products.map((product) => {
         if (product?.partNumber === value) {
           
           product.isSelected = false;
           product.buyQuantity = "0";
           setNumberOfSelectedProducts( prev => prev - 1);
         }
       });
 
       setSelectedProducts(products);
     }
   };
 
   const handleInputChange = (value, partNumber) => {
     const cloneSelecteProducts = JSON.parse(JSON.stringify(selectedProducts));
 
     cloneSelecteProducts.map((product) => {
       if (product?.partNumber === partNumber) {
         const actualProductQuantity = product.buyQuantity
         product.buyQuantity = value === "" ? "0" : value;
         if (value === "" || value === "0") {
           product.isSelected = false;
           if(parseInt(actualProductQuantity) > 0 && numberOfSelectedProducts > 0){
             setNumberOfSelectedProducts( prev => prev - 1);
           }
         }
         if (parseInt(value) > 0) {
           product.isSelected = true;
           if(actualProductQuantity === "0" && numberOfSelectedProducts < 3){
             setNumberOfSelectedProducts( prev => prev + 1);
 
           }
         }
       }
     });
 
     setSelectedProducts(cloneSelecteProducts);
   };
 
 
   if (storeBuyableExclude || !seconds) return null;
 
   const id = `widget_coordinate`;
   return (
     <div id={id}>
     <div id={`emarsys_bundle_items_${seconds}`}>
       {emarsysFetchedData.map(product => <span data-scarabitem={product?.partNumber} style={{display: "none"}} className="scarab-item"></span>)}
       {selectedProducts && selectedProducts?.length > 1 && 
       <ContainerStyledMerchandising className="styled--merchandising-container" >
         <StyledCardEmpty className="styled--merchandisingcard-container">
           <StyledTypography variant="textSegment" className="bundle-card-title bottom-margin-2">
             {recommendedProdTitle}
           </StyledTypography>
           <Divider />
 
           <StyledGrid container className="styled--allproducts-container top-margin-2">
             <StyledGrid xs={12} md={8} lg={8} className="styled-imageprice-container">
               <StyledGrid xs={12} md={isTablet ? 12 : 5} lg={5} className="styled--productimages-container">
                 <StyledBox className="styled--product-images">
                   <MerchandisingAssociationImages
                     selectedProducts={selectedProducts}
                     cdnUrl={cdnUrl}
                     LogoHDM={LogoHDM}
                     numberOfSelectedProducts={numberOfSelectedProducts}
                   />
                 </StyledBox>
               </StyledGrid>
 
               <StyledGrid xs={12} md={isTablet ? 12 : 7} lg={7} className="styled--productInfo-container">
                 {selectedProducts.map((product, index) => {
                   let thisProduct = false;
                   if (index === 0) {
                     thisProduct = true;
                   }
                   return (
                     <MerchandisingAssociationProductInfo
                       key={product?.partNumber}
                       product={product}
                       handleOnChange={handleOnChange}
                       handleInputChange={handleInputChange}
                       thisProduct={thisProduct}
                       className={index === 0 ? "current-product" : ""}
                     />
                   );
                 })}
               </StyledGrid>
             </StyledGrid>
 
             <StyledGrid xs={12} md={4} lg={4} className="styled--totalproduct-container">
               <MerchandisingAssociationTotalPrice selectedProducts={selectedProducts} isDesktop={isDesktop} />
             </StyledGrid>
           </StyledGrid>
         </StyledCardEmpty>
       </ContainerStyledMerchandising>
     }
     </div>
     </div>
   );
 };
 export { MerchandisingAssociationContent };
 